<template>
  <el-main>
    <div class="blodTitle">百度小程序</div>
    <img :style="{ marginBottom: isShouQuan ? '' : '20px' }" src="../../assets/image/baidu/baiduHead.png" alt="" />
    <!-- 暂未授权百度小程序 -->
    <div class="isAuthorization" v-if="!isShouQuan">
      <div class="Authorizations" v-for="(item, index) in 2" :key="index">
        <div class="top">{{ index == 0 ? '未有百度小程序' : '已有百度小程序' }}</div>
        <div class="mid">
          {{ index == 0 ? '在百度只能小程序平台注册小程序，完成注册后可以同步进行 信息完善和开发。' : '授权已有的百度小程序，完成支付配置设置，发布快速获得小程序' }}
        </div>
        <div class="bot" @click="regiOrGrant(index)">{{ index == 0 ? '快速注册' : '授权百度小程序' }}</div>
      </div>
    </div>
    <div class="blodTitle" v-if="isShouQuan && dataInfo">授权及发布小程序</div>
    <Bdempower @getIsAuth="getIsAuth" v-if="isShouQuan && dataInfo" :dataInfo="dataInfo"></Bdempower>
    <div class="blodTitle">其他配置</div>
    <div class="otherConfig">
      <div class="configItem" v-for="(item, index) in otherConifg" :key="index">
        <img :src="item.img" alt="" />
        <div class="conTxt">
          <div class="top">{{ item.title }}</div>
          <div class="mid">{{ item.content }}</div>
          <div class="bot">
            <div v-if="index === 1" @click.stop="Jump(index)">
              前往体验
              <div :style="{ height: qrcodeImgFlag ? '180px' : '0', padding: qrcodeImgFlag ? '10px' : '0' }" class="toExperience" @click.stop="() => {}">
                <p>请使用百度小程序扫描二维码</p>
                <img :src="qrcodeImg" alt="" />
              </div>
            </div>
            <div @click="Jump(index)" v-if="index === 0">前往配置</div>
          </div>
        </div>
      </div>
    </div>
    <div class="fourFunction">
      <div class="left">
        <div class="blodTitle">四大核心功能</div>
        <div class="leftBot">
          百度智能小程序是一种智能连接人与信息、人与服务、人与万物的开放生态，依托以百度App为代表的全域流量，通过百度A开放式赋能，精准连接用户，无需下载安装便可享受智慧超前的使用体验。
        </div>
      </div>
      <div class="right">
        <div :style="{ width: index <= 1 ? '140px' : '154px' }" v-for="(item, index) in fourFun" :key="index" class="functionItem">
          <img :src="item.img" alt="" />
          <div style="margin: 20px 0">{{ item.name }}</div>
          <div style="color: #666666">{{ item.desc }}</div>
        </div>
      </div>
    </div>
    <div class="blodTitle">多场景流量支持</div>
    <div class="manyScene">
      <div v-for="(item, index) in manyScene" :key="index" class="manySceneItem">
        <img :src="item.img" alt="" />
        <div style="margin: 20px 0">{{ item.name }}</div>
        <div style="color: #666666">{{ item.desc }}</div>
      </div>
    </div>
    <div class="blodTitle">优秀案例</div>
    <div class="goodCase">
      <div class="goodCaseItem" v-for="(item, index) in goodCase" :key="index">
        <img :src="item.img" alt="" />
        <div>{{ item.name }}</div>
      </div>
    </div>
  </el-main>
</template>

<script>
import Bdempower from './sonPage/baidu/baiduempower.vue'
export default {
  components: {
    Bdempower
  },
  data() {
    return {
      isShouQuan: false,
      dataInfo: null,
      fourFun: [
        { name: '体验', desc: '构建自然用户体验系统打造新时代最佳体验', img: require('@/assets/image/baidu/tiyan.png') },
        { name: '流量', desc: '开放百度全域千亿流量流量天然可运营', img: require('@/assets/image/baidu/liuliang.png') },
        { name: '智能', desc: 'AI 能力普及，让开发者重回业务理解和创意赛道', img: require('@/assets/image/baidu/zhineng .png') },
        { name: '开放', desc: '业界优先开放生态，打破孤岛共建繁荣', img: require('@/assets/image/baidu/kaifang.png') }
      ],
      manyScene: [
        { name: '搜索优先接入', desc: '用户搜索相关内容时，可进入小程序商品详情页', img: require('@/assets/image/baidu/pic01.png') },
        { name: '关键词配置', desc: '智能小程序被百度收录后，用户在百度APP搜索关键词可展现相关结果', img: require('@/assets/image/baidu/pic02.png') },
        { name: '智能小程序单卡', desc: '用户搜索小程序结果呈现小程序单卡，样式升级和服务直达', img: require('@/assets/image/baidu/pic03.png') },
        { name: '小程序商品卡', desc: '用户搜索商品词，以小程序商品卡片展示搜索结果', img: require('@/assets/image/baidu/pic04.png') },
        { name: '百家号文章挂载', desc: '百家号作者可以在其文章中间挂载关联相关智能小程序进行推荐分发', img: require('@/assets/image/baidu/pic05.png') }
      ],
      goodCase: [
        { name: '雅兰天丽美妆', img: require('@/assets/image/baidu/qr.png') },
        { name: '天宜世家家居', img: require('@/assets/image/baidu/qr.png') },
        { name: '伊乐贝斯女装', img: require('@/assets/image/baidu/qr.png') },
        { name: '日易惠百货用品', img: require('@/assets/image/baidu/qr.png') },
        { name: '新稻香农产品', img: require('@/assets/image/baidu/qr.png') }
      ],
      otherConifg: [
        {
          img: require('@/assets/image/baidu/dingyue.png'),
          title: '订阅消息',
          content:
            '针对于用户主动发起订阅某个信息的交互场景，如商品降价提醒、动漫开播通知、演唱会开售提醒等，订阅消息仅支持下发给登录用户，用户需登录后才能订阅成功。未登录用户点击订阅，会自动跳转至小程序联合登录界面，登录后订阅成功，若用户未登录（即关闭登录弹框），则订阅未成功。'
        },
        {
          img: require('@/assets/image/baidu/tiyanqrcode.png'),
          title: '体验小程序',
          content: '上传小程序后可生成体验码，可在手机上预览小程序。小程序未上线前，可以添加体验者微信号，即可手机浏览小程序。'
        }
      ],
      qrcodeImg: '',
      qrcodeImgFlag: false
    }
  },
  created() {
    this.getIsAuth()
  },
  mounted() {
    document.addEventListener('click', () => {
      this.qrcodeImgFlag = false
    })
  },
  methods: {
    // 注册or授权支付宝小程序
    regiOrGrant(index) {
      if (index == 0) {
        window.open('https://smartprogram.baidu.com')
      } else {
        let that = this
        this.$axios.post(this.$api.set.baidu.getAuthorizationUrl).then((res) => {
          if (res.code == 0) {
            that
              .$confirm('是否完成授权', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })
              .then(() => {
                this.getIsAuth(1)
              })
              .catch(() => {})
            window.open(res.result, '_blank')
          }
        })
      }
    },
    getIsAuth(status) {
      let that = this
      this.$axios.post(this.$api.set.baidu.isAuth).then((res) => {
        if (res.code === 0) {
          if (res.result) {
            if (status) {
              that.$message.success('授权成功')
            }
            this.dataInfo = res.result
            this.isShouQuan = true
          } else {
            if (status) {
              that.$message.error('授权未成功')
            }
            this.isShouQuan = false
          }
        }
      })
    },
    Jump(index) {
      if (index === 0) {
        // 订阅消息配置
      } else {
        // 获取体验码
        this.getQrcode()
      }
    },
    getQrcode() {
      if (this.qrcodeImgFlag) return
      if (!this.dataInfo || this.dataInfo.appstatus < 2) {
        this.$message.warning('请先上传小程序')
        return
      }
      let that = this
      this.$axios.post(this.$api.set.baidu.getQrcode).then((res) => {
        if (res.code == 0) {
          that.qrcodeImgFlag = true
          that.qrcodeImg = 'data:image/png;base64,' + res.result
        } else {
          that.$message.error('获取体验码失败,请先授权小程序')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  background-color: #fff;
  .blodTitle {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin: 40px 0 20px 0;
    .subTitle {
      color: #5654f3;
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
      margin-left: 23px;
    }
  }
  & > img {
    width: 100%;
  }
  .isAuthorization {
    width: 100%;
    display: flex;
    & > div {
      width: 380px;
      padding: 20px;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #f7f8fa;
      margin-right: 20px;
    }
    .top {
      font-size: 18px;
      color: #333;
      text-align: center;
      font-weight: bold;
    }
    .mid {
      width: 100%;
      font-size: 14px;
    }
    .bot {
      width: 228px;
      height: 32px;
      background: #1676fe;
      border-radius: 2px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      align-self: center;
    }
  }
  .fourFunction {
    margin-top: 20px;
    display: flex;
    .left {
      flex: 0 0 440px;
      flex-shrink: 0;
      font-size: 16px;
      line-height: 30px;
      margin-right: 122px;
    }
    .right {
      flex: 1;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      .functionItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .manyScene {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    .manySceneItem {
      flex: 0 0 230px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .goodCase {
    display: flex;
    flex-wrap: wrap;
    .goodCaseItem {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      text-align: center;
      flex-shrink: 0;
      margin-right: 185px;
      margin-bottom: 20px;
    }
  }
  .otherConfig {
    width: 100%;
    display: flex;
    .configItem {
      flex: 1;
      display: flex;
      align-items: center;
      border: 1px solid #ededed;
      border-radius: 2px;
      padding: 17px 20px;
      img {
        width: 50px;
        height: 50px;
      }
      .conTxt {
        margin-left: 20px;
        width: calc(100% - 65px);
        .top {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 4px;
        }
        .mid {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
        .bot {
          margin-top: 4px;
          & > div {
            display: inline-block;
            color: #1676fe;
            cursor: pointer;
            font-size: 14px;
            position: relative;
            .toExperience {
              width: 210px;
              position: absolute;
              left: 110%;
              top: 5px;
              text-align: center;
              background-color: #fff;
              box-shadow: 0 5px 10px #ccc;
              transition: all 0.3s;
              overflow: hidden;
              img {
                width: 150px;
                height: 150px;
              }
            }
          }
        }
      }
    }
    .configItem:last-child {
      margin-left: 15px;
    }
  }
}
</style>
