<template>
  <div>
    <div class="steps" v-for="(item, index) in authorizationArr" :key="index">
      <div class="top">
        <div
          class="left"
          v-if="authorizationSteps <= index"
          :style="{ background: authorizationSteps === index ? '#415AD8' : '#D8D8D8' }"
        >
          {{ index + 1 }}
        </div>
        <div class="left1" v-else>
          <i class="el-icon-check"></i>
        </div>
        <div class="mid">{{ item.stageTitle }}</div>
        <div class="right" v-if="index != 2">
          <div
            v-if="authorizationSteps <= index"
            class="canBtn"
            :style="{
              color: authorizationSteps === index ? '#fff' : '#999999',
              background: authorizationSteps === index ? '#415AD8' : '#EBEBEB'
            }"
            @click="btnFunction(index)"
          >
            {{ item.btnTxt }}
          </div>
          <div v-else class="rightElse">
            <div v-if="index === 0"><i class="el-icon-check"></i>已授权</div>
            <div v-if="index === 1"><i class="el-icon-check"></i>已配置</div>
            <div v-if="index === 3"><i class="el-icon-check"></i>已提审</div>
            <div v-if="index === 4"><i class="el-icon-check"></i>已发布</div>
          </div>
        </div>
        <div class="right" v-else>
          <div
            v-if="authorizationSteps < index"
            class="canBtn"
            :style="{ color: '#999999', background: '#EBEBEB' }"
          >
            {{ item.btnTxt }}
          </div>
          <div
            @click="btnFunction(index)"
            v-if="dataInfo.version !== dataInfo.new_version || authorizationSteps == index"
            class="canBtn"
            :style="{ color: '#fff', background: '#415AD8' }"
          >
            {{ item.btnTxt }}
          </div>
          <div
            v-if="dataInfo.version === dataInfo.new_version && authorizationSteps > index"
            class="rightElse"
          >
            <div><i class="el-icon-check"></i>已上传</div>
          </div>
        </div>
      </div>
      <div class="bot">
        <div class="left" v-if="index !== authorizationArr.length - 1">
          <div
            class="xian"
            :style="{ background: authorizationSteps >= index + 1 ? '#415AD8' : '#eeeeee' }"
          ></div>
        </div>
        <div class="mid">
          <div v-if="index === 0">
            <p class="fc999" v-if="dataInfo.appstatus >= 1">
              小程序名称：<b style="color: #333">{{ dataInfo.name }}</b>
            </p>
          </div>
          <div v-if="index === 1">
            <p>
              {{ 1 >= dataInfo.appstatus ? '暂未配置支付' : '支付配置成功' }}
            </p>
          </div>
          <div v-if="index === 2">
            <p>上传状态：{{ dataInfo.appstatus > 1 ? '已上传' : '未上传' }}</p>
            <p v-if="dataInfo.appstatus > 1">
              当前版本：<b style="color: #333">{{ dataInfo.version }}</b>
            </p>
            <p>
              最新版本：<b
                :style="{ color: dataInfo.version === dataInfo.new_version ? '#333' : 'red' }"
                >{{ dataInfo.new_version }}({{
                  dataInfo.version === dataInfo.new_version ? '已是最新版本' : '请立即上传最新版本'
                }})</b
              >
            </p>
          </div>
          <div v-if="index === 3">
            <p>提交百度审核（最长14个工作日），审核通过后即可立即发布版本</p>
            <p>
              审核状态：<b>{{ examineTxt }}</b>
            </p>
            <div v-if="examineRes" class="examineRes">
              <div>驳回原因：</div>
              <div class="shortRes">{{ examineRes }}</div>
              <div class="lookDetail" @click="lookExamineFlag = true">查看详情</div>
            </div>
          </div>
          <div v-if="index === 4">
            <p>
              发布状态：<b>{{ releaseTxt }}</b>
            </p>
          </div>
        </div>
        <div class="right">
          <div v-if="index === 0 && authorizationSteps > 0">
            <span @click="lookOne(index)">查看</span>
          </div>
          <div v-if="index === 1 && authorizationSteps > 1">
            <span @click="lookOnePay(index)">查看</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="基础配置" :visible.sync="basicSet" width="52%">
      <AliBasics :dataInfo="dataInfo" ref="AliBasics"></AliBasics>
    </el-dialog>
    <el-dialog
      title="填写小程序版本描述"
      :visible.sync="VersionDescFlag"
      width="52%"
      :before-close="handleClose"
    >
      <div class="descBox">
        <div class="title">小程序版本描述：</div>
        <el-input
          :spellcheck="false"
          type="textarea"
          :rows="4"
          placeholder="请输入小程序版本描述"
          v-model="getVersionDesc"
        >
        </el-input>
      </div>
      <div class="descBox">
        <div class="title"></div>
        <div
          class="tips"
          :style="{
            color: getVersionDesc.length >= 30 && getVersionDesc.length <= 500 ? '#415AD8' : ''
          }"
        >
          <span>小程序版本描述必须在30-500个字符之间</span>
          <span>{{ getVersionDesc.length }}/300</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="VersionDescFlag = false">取 消</el-button>
        <el-button type="primary" @click="getStatus">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看驳回详情" :visible.sync="lookExamineFlag" width="30%">
      <div class="lookExamine" v-html="examineRes"></div>
    </el-dialog>
    <el-dialog title="支付配置" :visible.sync="payConfigFlag" width="52%">
      <PayConfig ref="PayConfig" @baiduPaySureSu="baiduPaySureSu"></PayConfig>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payConfigFlag = false">取 消</el-button>
        <el-button type="primary" @click="getSurePay">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDateformat } from '../../../../util/getDate'
import AliBasics from './pay.vue'
import PayConfig from './payConfig.vue'
export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    AliBasics,
    PayConfig
  },
  filters: {
    getDate(index) {
      return getDateformat(index)
    }
  },
  data() {
    return {
      VersionDescFlag: false,
      getVersionDesc: '',
      isLengthOK: false,
      // 基础配置弹框
      basicSet: false,
      // 步骤状态
      authorizationSteps: 0,
      // 审核步骤合集
      authorizationArr: [
        { stageTitle: '已授权小程序', btnTxt: '查看信息' },
        { stageTitle: '配置支付信息', btnTxt: '立即配置' },
        { stageTitle: '配置成功，立即上传小程序', btnTxt: '立即上传' },
        { stageTitle: '上传小程序成功，立即提交审核', btnTxt: '提交审核' },
        { stageTitle: '审核成功，立即发布小程序', btnTxt: '立即发布' }
      ],
      // 审核信息
      examineTxt: '暂未提交审核',
      examineRes: '',
      // 发布信息
      releaseTxt: '暂未发布',
      lookExamineFlag: false,
      payConfigFlag: false
    }
  },
  created() {
    this.matchingSteps(this.dataInfo.appstatus)
    if (this.dataInfo.appstatus == 3) {
      this.checkStatus()
    }
  },
  methods: {
    // 确认修改基础配置
    updateBasics() {
      this.$refs.AliBasics.preservation()
    },
    // 查看配置弹框
    lookOne(index) {
      if (index == 0) {
        this.basicSet = true
      }
    },
    lookOnePay() {
      this.payConfigFlag = true
      this.$nextTick(() => {
        this.$refs.PayConfig.getInfo()
      })
    },
    // 点击配置按钮
    btnFunction(index) {
      if (index === 0) {
        this.basicSet = true
      } else if (index == 1) {
        this.lookOnePay()
      } else if (index == 2) {
        this.onceUpLoad()
      } else if (index == 3) {
        if (this.authorizationSteps != 3) return
        if (this.authorizationArr[3].btnTxt == '撤销审核') {
          this.getStatus()
        } else {
          this.VersionDescFlag = true
        }
      } else if (index == 4) {
        this.release()
      }
    },

    // 检测审核状态
    checkStatus() {
      switch (this.dataInfo.auditid_status) {
        case 0:
          this.examineTxt = '审核中'
          this.authorizationArr[3].btnTxt = '撤销审核'
          this.matchingSteps(2)
          break
        case 1:
          this.examineTxt = '审核成功'
          this.matchingSteps(3)
          break
        case 2:
          this.examineTxt = '审核失败'
          // 将所有的换行符更改为br
          this.examineRes = this.dataInfo.reason.replace(/\\n/g, '<br>')
          // 清除文本中的
          this.examineRes = this.examineRes.replace(/[\\]/g, '')
          this.matchingSteps(2)
          break
        default:
          break
      }
    },
    // 根据授权状态判断步骤
    matchingSteps(status) {
      if (status == 1) {
        // 已授权
        this.authorizationSteps = 1
      } else if (status == 2) {
        // 已上传
        this.authorizationSteps = 3
      } else if (status == 3) {
        // 已提交审核
        this.authorizationSteps = 4
      } else if (status == 6) {
        // 已发布
        this.examineTxt = '审核成功'
        this.releaseTxt = '发布成功'
        this.authorizationSteps = 5
      } else if (status == 7) {
        this.authorizationSteps = 2
      }
    },
    getSurePay() {
      this.$nextTick(() => {
        this.$refs.PayConfig.preservation()
      })
    },
    // 支付配置配置完成
    baiduPaySureSu() {
      this.matchingSteps(7)
    },
    // 立即上传
    onceUpLoad() {
      if (this.authorizationSteps < 1) return
      let that = this
      this.$axios.post(this.$api.set.baidu.upload).then((res) => {
        if (res.code == 0) {
          that.$message({
            message: '上传成功',
            type: 'success'
          })
          this.matchingSteps(2)
          this.dataInfo.version = this.dataInfo.new_version
        } else {
          that.$message({
            message: `${res.msg}`,
            type: 'warning'
          })
        }
      })
    },
    // 点击提交审核
    getStatus() {
      let that = this
      if (this.authorizationArr[3].btnTxt == '撤销审核') {
        this.$axios.post(this.$api.set.baidu.withdraw).then((res) => {
          if (res.code == 0) {
            that.$message.success('撤销审核成功')
            this.authorizationArr[3].btnTxt = '提交审核'
            this.examineTxt = '未提交审核'
            this.$emit('getIsAuth')
          } else {
            that.$message.error(`${res.msg}`)
          }
        })
        return
      }
      if (this.getVersionDesc.length < 30 || this.getVersionDesc.length > 500) {
        this.$message.warning('请按要求填写信息')
        return
      }
      this.$axios
        .post(this.$api.set.baidu.submitAudit, {
          version_desc: this.getVersionDesc
        })
        .then((res) => {
          if (res.code == 0) {
            that.$message({
              message: '成功提交审核',
              type: 'success'
            })
            this.authorizationArr[3].btnTxt = '撤销审核'
            this.VersionDescFlag = false
            this.examineTxt = '审核中'
          } else {
            that.$message({
              message: `${res.msg}`,
              type: 'error'
            })
          }
        })
    },
    // 立即发布
    release() {
      if (this.authorizationSteps !== 4) return
      let that = this
      this.$axios.post(this.$api.set.baidu.release, {}).then((res) => {
        if (res.code == 0) {
          that.$message({
            message: '发布成功',
            type: 'success'
          })
          this.releaseTxt = '发布成功'
          this.matchingSteps(6)
        } else {
          that.$message({
            message: `${res.msg}`,
            type: 'error'
          })
        }
      })
    }
  },
  watch: {
    'dataInfo.appstatus'(val) {
      this.matchingSteps(val)
    }
  }
}
</script>

<style lang="less" scoped>
.cupo {
  cursor: pointer;
}
.fc666 {
  color: #666 !important;
}
.fc409 {
  color: #409eff;
}
.fc999 {
  color: #999 !important;
}
.fc852 {
  color: #415ad8 !important;
  cursor: pointer;
}
.canBtn {
  width: 96px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
}
.steps {
  width: 100%;
  & > div {
    display: flex;
    justify-content: space-between;
    .left {
      flex: 0 0 24px;
    }
    .mid {
      flex: 1;
      padding: 0 10px;
    }
    .right {
      flex: 0 0 96px;
    }
  }
  .top {
    align-items: center;
    .left {
      height: 24px;
      text-align: center;
      line-height: 24px;
      color: #fff;
      border-radius: 50%;
    }
    .left1 {
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 22px;
      border: 2px solid #415ad8;
      border-radius: 50%;
      i {
        color: #415ad8;
        font-weight: bold;
      }
    }
    .mid {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    .rightElse {
      flex-wrap: nowrap;
      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        i {
          width: 16px;
          height: 16px;
          background: #415ad8;
          border-radius: 50%;
          color: #fff;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          font-weight: bold;
          margin-right: 8px;
        }
      }
    }
  }
  .bot {
    min-height: 100px;
    .left {
      .xian {
        width: 2px;
        min-height: 80px;
        margin: 10px auto;
      }
    }
    .mid {
      & > div {
        p {
          font-size: 14px;
          color: #666;
          margin-top: 5px;
        }
      }
      .examineRes {
        margin-top: 5px;
        display: flex;
        font-size: 14px;
        .shortRes {
          color: red;
          width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .lookDetail {
          cursor: pointer;
          color: #415ad8;
          margin-left: 10px;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      div {
        text-align: right;
      }
      span {
        cursor: pointer;
        user-select: none;
        align-self: flex-end;
        font-size: 14px;
        color: #415ad8;
      }
    }
  }
  .bot:last-child {
    min-height: 50px;
  }
}
.el-table {
  margin-bottom: 10px;
  /deep/ .el-table__body-wrapper {
    height: 40vh;
    overflow: hidden;
    overflow-y: auto;
  }
}
.descBox {
  display: flex;
  .title {
    width: 120px;
    text-align: right;
    flex-shrink: 0;
  }
  .el-textarea {
    /deep/ .el-textarea__inner {
      resize: none;
    }
  }
  .tips {
    width: calc(100% - 120px);
    color: red;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.lookExamine {
  line-height: 20px;
  & /deep/ img {
    width: 100%;
  }
}
</style>
